<template>
  <vs-dropdown class="cursor-pointer mb-4 md:mb-0 mr-4 hidden md:block" vs-trigger-click>
    <div
      class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">
                {{ currentPage * limit - (limit - 1) }} -
                {{
                  total - currentPage * limit > 0 ? currentPage * limit : total
                }} of
                {{ total }}
              </span>
      <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
    </div>
    <vs-dropdown-menu>
      <vs-dropdown-item @click="changeLimit(5)"><span
          :class="{ 'text-primary font-bold': limit === 5 }">5</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="changeLimit(10)"><span
        :class="{ 'text-primary font-bold': limit === 10 }">10</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="changeLimit(20)"><span
        :class="{ 'text-primary font-bold': limit === 20 }">20</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="changeLimit(50)"><span
        :class="{ 'text-primary font-bold': limit === 50 }">50</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="changeLimit(100)"><span
        :class="{ 'text-primary font-bold': limit === 100 }">100</span>
      </vs-dropdown-item>
      <vs-dropdown-item @click="changeLimit(200)"><span
        :class="{ 'text-primary font-bold': limit === 200 }">200</span>
      </vs-dropdown-item>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
export default {
  name: 'PagingLimit',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  methods: {
    changeLimit (limit) {
      this.$emit('changeLimit', limit)
    }
  }
}
</script>
